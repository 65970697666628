/* eslint-disable no-nested-ternary */
import React from "react";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import useMediaQuery from "@restart/hooks/useMediaQuery";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import { createAction, ACTION_TYPES, SECTION_IDS } from "@Utils";
import { ReadMoreWrapper } from "@Modules/common/ReadMoreWrapper";
import { ITemplate } from "@Templates/ITemplate";
import { AwardsList } from "@Modules/hostPage/CreatorMiscellaneousData/Components/AwardsList";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { FollowerCountIcons } from "@Modules/hostPage/FollowerCountIcons";
import { CUSTOM_CODES_POSITION } from "repoV2/constants/customCodes/customCodes.constants";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import {
    TEMPLATE_CONFIG,
    TEMPLATE_KEYS,
} from "repoV2/features/CreatorPages/CreatorPages.constants";
import styles from "./hostInfoPanel.module.scss";
import { CustomCodeSection } from "../CustomCodeSection/CustomCodeSection";

const ReactPlayer = dynamic(() => import("react-player/lazy"));

export const HostInfoPanel = ({
    coverImg,
    profileImg,
    introVideo,
    preview,
    hideSocialIcons,
    socialLinks,
    hideGetInTouch,
    getInTouchCtaText,
    onGetInTouchClick,
    shortDescription,
    name,
    description,
    expandHostDesc,
}: ITemplate.IAboutSectionProps): JSX.Element => {
    const dispatch = useDispatch();
    const isDesktopOrLaptop = useMediaQuery("(min-device-width: 768px)");
    const defaultProfilePic = DEFAULT_PROFILE_PIC_SRC;
    const defaultCoverPic = "/static/images/cover_background.png";
    const postAnalyticalEvent = (eventDetails: Object) => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.POST_ANALYTICS, {
                ...eventDetails,
                screen: "mypageScreen",
                funnel: "mypage",
                flow: "mypage",
            })
        );
    };

    return (
        <section>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a id={SECTION_IDS.about} />
            <div className={styles.container}>
                <div className={styles.images}>
                    {coverImg ? (
                        <ExlyImage
                            fetchWidth={
                                TEMPLATE_CONFIG[TEMPLATE_KEYS.CLASSIC]
                                    .coverImage.width
                            }
                            width={
                                TEMPLATE_CONFIG[TEMPLATE_KEYS.CLASSIC]
                                    .coverImage.width
                            }
                            src={coverImg}
                            alt="cover"
                            loading="lazy"
                            className={styles.coverImage}
                        />
                    ) : preview ? (
                        <h1
                            className={styles.coverImage}
                            style={{
                                backgroundColor: "#f2f2f4",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#555",
                                textAlign: "center",
                            }}
                        >
                            {"< Your cover image will be displayed here >"}
                        </h1>
                    ) : (
                        <img
                            src={defaultCoverPic}
                            alt="cover"
                            loading="lazy"
                            className={styles.coverImage}
                        />
                    )}
                    {profileImg ? (
                        <ExlyImage
                            fetchWidth={200}
                            src={profileImg}
                            alt="profile_pic"
                            loading="lazy"
                            className={styles.profilePic}
                        />
                    ) : preview ? (
                        <div
                            className={styles.profilePic}
                            style={{
                                backgroundColor: "#f2f2f4",
                                width: 180,
                                height: 180,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#555",
                                opacity: 0.9,
                            }}
                        >
                            {"< Your profile pic will be displayed here >"}
                        </div>
                    ) : (
                        <img
                            src={defaultProfilePic}
                            alt="profile_pic"
                            loading="lazy"
                            className={styles.profilePic}
                        />
                    )}
                </div>
                {name ? (
                    <div className={styles.nameContainer}>
                        <h1 className={styles.nameText}>{name}</h1>
                    </div>
                ) : null}
                {shortDescription ? (
                    <div className={styles.bioText}>{shortDescription}</div>
                ) : null}

                <FollowerCountIcons
                    socialLinks={socialLinks}
                    hideSocialIcons={hideSocialIcons}
                />
                {(introVideo && (
                    <div
                        className={`${styles.videoContainer} ${
                            preview ? styles.videoContainerOverlay : ""
                        }`}
                    >
                        <ReactPlayer
                            url={introVideo}
                            controls
                            width={isDesktopOrLaptop ? "40%" : "90%"}
                            onPlay={() => {
                                postAnalyticalEvent({
                                    event_name: "playVideo",
                                    action: "Tap",
                                    ui_component: "Button",
                                });
                            }}
                        />
                    </div>
                )) ||
                    (preview && (
                        <div className={styles.placeholderContainer}>
                            {"<Your intro video will be displayed here>"}
                        </div>
                    )) ||
                    null}
                <div className={styles.descriptionContainer}>
                    {description ? (
                        <>
                            {expandHostDesc ? (
                                <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            ) : (
                                <ReadMoreWrapper
                                    id="classic_aboutSection_description"
                                    collapsedHeight={200}
                                    clickCallback={() => {
                                        if (!preview) {
                                            postAnalyticalEvent({
                                                event_name: "moreDesc",
                                                action: "Tap",
                                                ui_component: "Text Button",
                                                identifier: " + More",
                                            });
                                        }
                                    }}
                                >
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </ReadMoreWrapper>
                            )}
                        </>
                    ) : null}
                </div>

                <CustomCodeSection
                    position={CUSTOM_CODES_POSITION.MAIN_CONTENT_START}
                />

                <AwardsList titleClass={`${styles.sectionTitle}`} />

                {hideGetInTouch ? null : (
                    <div className={styles.getInTouchContainer}>
                        <div className={styles.getInTouchText}>
                            I would love to hear from you. Send me a message or
                            ask me any question.
                        </div>
                        <ButtonV2
                            type="button"
                            applyTheme
                            className={styles.ctaButton}
                            onClick={() => {
                                onGetInTouchClick();
                            }}
                        >
                            {getInTouchCtaText}
                        </ButtonV2>
                    </div>
                )}
            </div>
        </section>
    );
};
