import React from "react";
import { isEmpty, MEDIA, colorByListingType } from "@Utils";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { IEventCard } from "./EventCard";
import styles from "./eventcard.module.scss";

export const EventCard = ({
    title,
    image,
    shortDescription,
    onShareClick,
    onClickBook,
    onHeartClick,
    metadata,
    eventType,
    currency,
    updated_price: updatedPrice,
    listingTypeTitle,
    showSkuLabelAsTag,
    isPlan,
    ctaText,
}: IEventCard.IProps): JSX.Element => {
    const heart = MEDIA.DEFAULT.CARD_HEART;
    const share = MEDIA.DEFAULT.CARD_SHARE;
    return (
        <section>
            <div className={styles.container}>
                <div className={styles.eventCardContainer}>
                    <div
                        className={styles.imageContainer}
                        role="presentation"
                        onClick={onClickBook}
                    >
                        <div className={styles.eventTagView}>
                            {showSkuLabelAsTag && listingTypeTitle ? (
                                <span
                                    className={styles.leftContent}
                                    style={{
                                        backgroundColor: colorByListingType(
                                            eventType,
                                            isPlan
                                        ),
                                    }}
                                >
                                    {listingTypeTitle}
                                </span>
                            ) : null}
                            {metadata?.card_label ? (
                                <>
                                    <div
                                        className={styles.rectangle}
                                        style={{
                                            backgroundColor:
                                                !isEmpty(metadata.card_label) &&
                                                !isEmpty(
                                                    metadata.card_label
                                                        .background
                                                )
                                                    ? metadata.card_label
                                                          .background
                                                    : "black",
                                        }}
                                    >
                                        <div
                                            className={styles.tagTitleStyle}
                                            style={{
                                                color:
                                                    metadata?.card_label
                                                        ?.color || "white",
                                                backgroundColor:
                                                    metadata?.card_label
                                                        ?.background || "black",
                                            }}
                                        >
                                            {metadata?.card_label?.content}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.triangleCorner}
                                        style={{
                                            borderTopColor:
                                                metadata?.card_label
                                                    ?.background || "black",
                                        }}
                                    />
                                </>
                            ) : null}
                        </div>
                        <ExlyImage
                            fetchWidth={600}
                            className={styles.eventImage}
                            alt=""
                            src={image || MEDIA.DEFAULT.CARD_IMAGE}
                            loading="lazy"
                        />
                        <div className={styles.cardIconSet}>
                            <img
                                src={heart}
                                alt=""
                                role="presentation"
                                className={styles.iconStyle}
                                loading="lazy"
                                onClick={e => {
                                    e.stopPropagation();
                                    onHeartClick();
                                }}
                            />
                            <img
                                src={share}
                                alt=""
                                role="presentation"
                                className={styles.iconStyle}
                                loading="lazy"
                                onClick={e => {
                                    e.stopPropagation();
                                    onShareClick();
                                }}
                            />
                        </div>
                    </div>
                    <div
                        role="presentation"
                        className={styles.eventDetail}
                        onClick={onClickBook}
                    >
                        <b className={styles.titleStyle}>{title}</b>
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: metadata?.hide_schedule
                                    ? `${currency} ${Math.round(updatedPrice)}`
                                    : `${(shortDescription || "")?.slice(
                                          0,
                                          180
                                      )}${
                                          shortDescription?.length > 180
                                              ? "..."
                                              : ""
                                      }` || "",
                            }}
                            className={styles.short_description_text}
                        />
                    </div>
                    <div className={styles.stickyBookCta}>
                        <ButtonV2
                            applyTheme
                            className={styles.bookNowButton}
                            onClick={onClickBook}
                        >
                            {ctaText}
                        </ButtonV2>
                    </div>
                </div>
            </div>
        </section>
    );
};
