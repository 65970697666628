import React, { useRef } from "react";
import ReactPlayer from "react-player/lazy";

import { MEDIA, trimText } from "@Utils";

import { VideoPlayer } from "@Modules/common/";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./galleryCard.module.scss";
import { IGalleryCard } from "./GalleryCard";

export const GalleryCard = ({
    type = "image",
    source,
    title,
    onClickShare,
    onClick,
}: IGalleryCard.IProps): JSX.Element => {
    const playerRef = useRef<ReactPlayer | null>(null);

    return (
        <div className={styles.container}>
            <div className={styles.galleryCardContainer}>
                {(["image", "photo", ""].includes(type) && (
                    <div role="presentation" onClick={onClick}>
                        <ExlyImage
                            fetchWidth={500}
                            className={styles.imageContainer}
                            src={source}
                            loading="lazy"
                            alt=""
                        />
                    </div>
                )) ||
                    (type === "video" && (
                        <div
                            role="presentation"
                            className={styles.videoContainer}
                            onClick={onClick}
                        >
                            <VideoPlayer
                                videoUrl={source}
                                ref={playerRef}
                                light
                                optimizeUsingThumbnail
                                controls
                                height="min(90vw, 340px)"
                                width="100%"
                            />
                        </div>
                    )) ||
                    null}
                <div className={styles.cardIconSet}>
                    <img
                        src={MEDIA.DEFAULT.CARD_SHARE}
                        className={styles.iconStyle}
                        loading="lazy"
                        alt=""
                        role="presentation"
                        onClick={e => {
                            e.stopPropagation();
                            onClickShare();
                        }}
                    />
                </div>
            </div>
            <div className={styles.caption}>{trimText(title, 70)}</div>
        </div>
    );
};
