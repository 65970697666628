import React from "react";
import styles from "./galleryPlaceholder.module.scss";

export const GalleryPlaceholder = ({
    item,
    onClick,
}: {
    item: number;
    onClick: () => void;
}): JSX.Element => (
    <div className={styles.root} onClick={onClick}>
        <div className={styles.cardImage} />
        <div className={styles.caption}>Gallery Caption {item}</div>
    </div>
);
