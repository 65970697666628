import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import {
    ImagePreviewIconSvg,
    QuoteBeginIconSvg,
    TESTIMONIAL_DATA_TYPE,
    trimText,
} from "@Utils";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import { ITemplate } from "@Templates/ITemplate";
import VideoPlayer from "repoV2/components/common/VideoPlayer/VideoPlayer";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import styles from "./testimonialCard.module.scss";

export const TestimonialCard = ({
    data,
    onTestimonialClick,
}: ITemplate.ITestimonialCardProps): JSX.Element => {
    const AuthorSection = (): JSX.Element => {
        return (
            <div className={styles.authorContainer}>
                <ExlyImage
                    fetchWidth={96}
                    className={styles.authorImage}
                    alt=""
                    loading="lazy"
                    src={data.cover_image}
                />
                <div className={styles.authorName}>
                    {trimText(data.name, 30)}
                </div>
            </div>
        );
    };

    if (data?.type === TESTIMONIAL_DATA_TYPE.VIDEO) {
        return (
            <div className={styles.container}>
                <div className={styles.mediaContainer}>
                    <div
                        className={styles.mediaOverlay}
                        onClick={onTestimonialClick}
                    />
                    <VideoPlayer
                        light
                        optimizeUsingThumbnail
                        videoUrl={data.media}
                        width="100%"
                        height="100%"
                        className={styles.video}
                    />
                </div>
                <AuthorSection />
            </div>
        );
    }

    if (data?.type === TESTIMONIAL_DATA_TYPE.IMAGE) {
        return (
            <div className={styles.container}>
                <div
                    className={classNames(
                        styles.mediaContainer,
                        styles.imageContainer
                    )}
                >
                    <div
                        className={classNames(
                            styles.mediaOverlay,
                            styles.imageOverlay
                        )}
                        onClick={onTestimonialClick}
                    >
                        <ImagePreviewIconSvg height="40px" />
                    </div>
                    <ExlyImage
                        fetchWidth={500}
                        alt={data.content}
                        src={data.media}
                        className={styles.image}
                    />
                </div>
                <AuthorSection />
            </div>
        );
    }

    let maxlength: number = 800;
    const deviceWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    if (deviceWidth > 1000) maxlength = 800;
    else if (deviceWidth > 600) maxlength = 700;
    else if (deviceWidth > 400) maxlength = 450;
    else if (deviceWidth > 350) maxlength = 300;
    else maxlength = 200;

    const showReadMore: boolean = !!(data?.content?.length > maxlength);

    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                <QuoteBeginIconSvg className={styles.quote} />
                <div className={styles.text}>
                    {trimText(data.content, maxlength)}
                    {showReadMore ? (
                        <>
                            &nbsp;
                            <ButtonV2
                                design="plain"
                                applyTheme
                                onClick={onTestimonialClick}
                                className={styles.readMore}
                            >
                                Read More
                            </ButtonV2>
                        </>
                    ) : null}
                </div>
            </div>
            <AuthorSection />
        </div>
    );
};
