/* eslint-disable react/require-default-props */
import { CarouselCustomDot } from "@Modules/common/CarouselCustomDot";
import { ITemplate } from "@Templates/ITemplate";
import {
    DotMatrixIconSvg,
    getItemCountFromResponsive,
    SECTION_IDS,
} from "@Utils";
import React from "react";
import Carousel from "react-multi-carousel";
import { TeamMemberCard } from "./components/TeamMemberCard";
import styles from "./TeamSection.module.scss";

const responsive = {
    largest: {
        breakpoint: { max: 4000, min: 1351 },
        items: 4,
    },
    large: {
        breakpoint: { max: 1350, min: 1001 },
        items: 3,
    },
    medium: {
        breakpoint: { max: 1000, min: 681 },
        items: 2,
    },
    small: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
    },
};

export const TeamSection = ({
    hideComponent,
    teamMembersData,
    title,
    cardClick,
}: ITemplate.ITeamSectionProps) => {
    if (hideComponent) return null;

    return (
        <div className={styles.teamSectionContainer}>
            <div id={SECTION_IDS.team} className={styles.teamAnchor} />
            <DotMatrixIconSvg className={styles.dotMatrix} />
            <div className={styles.teamSectionSubContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.carouselContainer}>
                    <Carousel
                        responsive={responsive}
                        infinite
                        renderButtonGroupOutside
                        arrows={false}
                        {...(teamMembersData.length >
                            getItemCountFromResponsive(responsive) && {
                            showDots: true,
                            renderDotsOutside: true,
                            customDot: (
                                <CarouselCustomDot
                                    design="horizontal bar"
                                    className={styles.customDot}
                                    applyTheme
                                />
                            ),
                            autoPlay: true,
                        })}
                    >
                        {teamMembersData.map(item => (
                            <TeamMemberCard
                                key={item?.username}
                                data={item}
                                cardClick={cardClick}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};
