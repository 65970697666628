/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Carousel from "react-multi-carousel";
import {
    DotMatrixIconSvg,
    getItemCountFromResponsive,
    SECTION_IDS,
} from "@Utils";
import { CarouselCustomDot } from "@Modules/common/CarouselCustomDot";
import { ITemplate } from "@Templates/ITemplate";
import styles from "./testimonialSection.module.scss";
import { TestimonialCard } from "./components/TestimonialCard";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

export const TestimonialSection = ({
    hideComponent,
    testimonialsData,
    title,
    onTestimonialClick,
    pauseTestimonialsAutoplay,
}: ITemplate.ITestimonialsSectionProps) => {
    if (hideComponent) return null;

    return (
        <>
            <a
                id={SECTION_IDS.testimonials}
                className={styles.anchorTestimonial}
            />
            <div className={styles.root}>
                <div className={styles.title}>{title}</div>
                <div className={styles.carouselContainer}>
                    <Carousel
                        responsive={responsive}
                        arrows={false}
                        showDots
                        autoPlay={
                            testimonialsData.length >
                                getItemCountFromResponsive(responsive) &&
                            !pauseTestimonialsAutoplay
                        }
                        infinite
                        renderDotsOutside
                        customDot={
                            <CarouselCustomDot
                                design="horizontal bar"
                                applyTheme
                            />
                        }
                        className={styles.carousel}
                    >
                        {testimonialsData.map(testimonial => (
                            <TestimonialCard
                                data={testimonial}
                                onTestimonialClick={e =>
                                    onTestimonialClick(e, testimonial)
                                }
                                key={testimonial.id}
                            />
                        ))}
                    </Carousel>
                    <DotMatrixIconSvg className={styles.dotMatrix} />
                </div>
            </div>
        </>
    );
};
